import React from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { PageTitle, Section } from '@/components/Static/Common';
import styled from 'styled-components';

const PhotoCell = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  text-align: center;
`;

const PhotoGrid = styled.div`
  display: flex;
`;

const VocabularyIndex = () => {
  const [documents] = useAllPrismicDocumentsByType('vocabulary_list', {
    graphQuery: `{
      vocabulary_list {
        display_name
        region
        poster
      }
    }`,
    orderings: [
      { field: "my.vocabulary_list.display_name", direction: "asc" }
    ]
  });

  return (
    <>
      <PageTitle variant="h4">Vocabulary Lists</PageTitle>
      <Section>
        <PhotoGrid>
          {documents && documents.map((document) => {
            return (
                  <PhotoCell key={document.uid}>
                    <Link component={RouterLink} underline="hover" variant="h6" to={document.uid}>
                      <img src={document.data.poster.url} />
                      <div>{document.data.display_name} ({document.data.region}) </div>
                    </Link>
                  </PhotoCell>
            );
          })}
        </PhotoGrid>
      </Section>
    </>
  );
};

export default VocabularyIndex;
