import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from '@/components/Shared';

import { StaticAppWrapper } from '@/components/Static/Common/StaticLayout';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import HistoryContainer from './History';
import BookmarksContainer from './Bookmarks/BookmarksContainer';
import StatsList from './StatsList';

function computeLinkPath(questionType) {
  switch (questionType) {
    case 'Question::DirectIndirectObject':
      return '/direct-indirect-object';
    case 'SentenceConjugation':
      return '/verb-cloze';
    case 'Question::ParaPor':
      return '/para-por';
    case 'Question::SubjunctiveIndicative':
      return '/subjunctive-indicative';
    case 'Question::VerbTakesPreposition':
      return '/verb-preposition';
    default:
      Error(`${questionType} is not a valid option.`);
  }
}

function computeLinkDisplay(questionType) {
  switch (questionType) {
    case 'SentenceConjugation':
      return 'Verb Cloze';
    case 'Question::ParaPor':
      return 'Para/Por';
    case 'Question::DirectIndirectObject':
      return 'Direct/Indirect Object';
    case 'Question::SubjunctiveIndicative':
      return 'Subjunctive/Indicative';
    case 'Question::VerbTakesPreposition':
      return 'Verbs with Prepositions';
    default:
      Error(`${questionType} is not a valid option.`);
  }
}

const DashboardBody = styled(StaticAppWrapper)`
  ${({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;

    ${theme.breakpoints.up('sm')} {
      padding-top: 10px;
    }

    p {
      text-align: center;
    }
  `}
`;

const StyledButton = styled(Button)`
  text-transform: capitalize;
  margin-top: 45px;
  margin-bottom: 20px;
`;

const ResetLink = styled.div`
  ${({ theme }) => `
    text-decoration: underline;
    align-self: flex-end;
    cursor: pointer;
    font-size: 14px;

    ${theme.breakpoints.down('sm')} {
      text-align: right;
    }
  `}
`;

const StyledTabPanel = styled(TabPanel)`
  min-height: 250px;
  width: 100%;
`;

const AuthenticatedDashboard = ({ lastTypePracticed, resetData, stats }) => {
  const navigate = useNavigate();

  const handlePracticeClick = () => {
    navigate(computeLinkPath(lastTypePracticed));
  };
  const theme = useTheme();
  const [tabState, setTabState] = useState('history');

  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleTabChange = (event, newValue) => {
    setTabState(newValue);
  };

  return (
    <DashboardBody>
      <StatsList disabled={!lastTypePracticed} stats={stats} resetData={resetData} />
      {!mobile && <ResetLink data-cy="reset-button" onClick={resetData}>Reset Statistics</ResetLink>}
      {lastTypePracticed && (
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handlePracticeClick}
          data-cy="resume-practice-button"
        >
          Continue practicing
          {' '}
          {computeLinkDisplay(lastTypePracticed)}
        </StyledButton>
      )}

      <Tabs
        value={tabState}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        data-cy="tabs"
      >
        <Tab value="history" label="History" data-cy="history-tab" />
        <Tab value="bookmarks" label="Bookmarks" data-cy="bookmarks-tab" />
      </Tabs>

      <StyledTabPanel value={tabState} index="history">
        <HistoryContainer shouldFetch={!!lastTypePracticed} />
        {!lastTypePracticed && (
          <Typography data-cy="no-previous-practice">
            Set which
            {' '}
            <Link to="/profile/verb-tenses">verb tenses</Link>
            {' '}
            you're comfortable seeing, and then choose an option from the menu
            to start practicing and improving your Spanish!
          </Typography>
        )}
      </StyledTabPanel>
      <StyledTabPanel value={tabState} index="bookmarks">
        <BookmarksContainer />
      </StyledTabPanel>
    </DashboardBody>
  );
};

AuthenticatedDashboard.propTypes = {
  lastTypePracticed: PropTypes.string,
  stats: PropTypes.object,
  resetData: PropTypes.func.isRequired,
};

AuthenticatedDashboard.defaultProps = {
  lastTypePracticed: null,
  stats: null,
};

export default AuthenticatedDashboard;
