import React from 'react';
import Typography from '@mui/material/Typography';
import { usePrismicDocumentByUID } from '@prismicio/react';
import { useParams } from "react-router-dom";
import { PageTitle, Section } from '@/components/Static/Common';
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

const sortedItems = (document) => {
  if (!document) return [];

  const items = document.data['vocabulary-item'];

  return items.sort((a, b) => String(a.word).localeCompare(String(b.word)));
}

const getSource = (link) => {
  if (link.includes('netflix')) {
    return 'Netflix'
  }

  if (link.includes('rtve')) {
    return 'RTVE'
  }
}

const VocabularyPage = () => {
  const { show } = useParams();

  const [document] = usePrismicDocumentByUID('vocabulary_list', show, {
    graphQuery: `{
      vocabulary_list {
        display_name
        vocabulary-item
        link
      }
    }`
  });

  const items = sortedItems(document);
  console.log(document)
  return (
    <>
      {document && <PageTitle variant="h4">{document.data.display_name} <Link component={RouterLink} variant="h6" underline="none" to={document.data.link.url}>(Available on {getSource(document.data.link.url)})</Link></PageTitle>}
      {items.map((item) => {
        return (
          <Section key={item.word}>
            <Typography variant="body1">{item.word.toLowerCase()}</Typography>
          </Section>
        );
      })}
    </>
  );
};

export default VocabularyPage;
